import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import Content, { HTMLContent } from '../components/layout/Content'
import PreviewCompatibleImage from '../components/image/PreviewCompatibleImage'

export const CafePageTemplate = ({ title, main, content, contentComponent }) => {
    const PageContent = contentComponent || Content

    const imageInfo1 = {
        "alt": "Des créations céramique et modelage diverses",
        "image": "/img/v2/Blelieu-min.JPG",
        "maxWidth": 432
    }

    return (
      <section className="section section--gradient" style={{ textAlign: 'justify' }}>
          <div className="container">
              <div className="columns">
                  <div className="column is-12">
                      <div className="columns is-centered">
                          <div className="column is-7">
                              <PageContent className="content" content={content} />
                          </div>

                          <div className="column is-4 has-text-centered" style={{margin:'auto'}}>
                              <PreviewCompatibleImage className="m-auto" imageInfo={imageInfo1}  style={{alignSelf:"center", verticalAlign:'middle'}}/>
                          </div>
                      </div>
                      <p></p>
                      {/*<div className="tile is-ancestor">*/}
                      {/*    <div className="tile is-horizontal">*/}
                      {/*        <div className="tile is-parent" style={{padding : "2rem"}}>*/}
                      {/*            <div className="tile is-child cafe-details">*/}
                      {/*                <h3 className="title is-size-4" style={{color : "white"}}>Nos fournisseurs</h3>*/}
                      {/*                <ul style={{listStyle : "disc outside"}}>*/}
                      {/*                    <li>Le Bon Label, café de spécialité élaboré en collaboration avec CAFEYEAH</li>*/}
                      {/*                    <li>La Bonne pioche, Mangez Bio Isère et Biocoop, pour l’épicerie sèche en vrac</li>*/}
                      {/*                    <li>Des producteurs locaux, pour le marché de fruits et légumes</li>*/}
                      {/*                    <li>La coopérative laitière de haute tarentaise</li>*/}
                      {/*                    <li>Les fruits du Val-qui-rit, pour les jus artisanaux isérois</li>*/}
                      {/*                    <li>Le jardin du thé</li>*/}
                      {/*                    <li>Lokki pour la kombucha bio et locale</li>*/}
                      {/*                    <li>La brasserie du loup blanc, pour les bières artisanales ardéchoises</li>*/}
                      {/*                </ul>*/}
                      {/*            </div>*/}
                      {/*        </div>*/}
                      {/*        <div className="tile is-parent" style={{padding : "2rem"}}>*/}
                      {/*            <div className="tile is-child cafe-details">*/}
                      {/*                <h3 className="title is-size-4 is-white" style={{color : "white"}}>Nos offres télétravail</h3>*/}
                      {/*                <ul style={{listStyle : "disc outside"}}>*/}
                      {/*                    <li>Petit déjeuner : <br/>*/}
                      {/*                        Du mercredi au vendredi entre 9h et 11h. <br/>*/}
                      {/*                        10€ pour deux heures de travail et un*/}
                      {/*                        petit- déjeuner.</li>*/}
                      {/*                    <br/>*/}
                      {/*                    <li> Après-midi : <br/>*/}
                      {/*                        Du mardi au vendredi entre 14h et 18h.<br/>*/}
                      {/*                        12€ pour quatre heure de travail*/}
                      {/*                        maximum et un goûter.</li>*/}
                      {/*                    <br/>*/}
                      {/*                    <li>Journée complète : <br/>*/}
                      {/*                        Du mardi au vendredi pendant nos heures*/}
                      {/*                        d’ouvertures.<br/>*/}
                      {/*                        25€ pour temps de travail et une formule*/}
                      {/*                        repas le midi.</li>*/}
                      {/*                </ul>*/}
                      {/*            </div>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                  </div>
              </div>
          </div>
      </section>
    )
}

CafePageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    main: PropTypes.shape({
        image0: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const LeLieuPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
      <Layout>
          <CafePageTemplate
            contentComponent={HTMLContent}
            title={post.frontmatter.title}
            content={post.html}
            main={post.frontmatter.main}
          />
      </Layout>
    )
}

LeLieuPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default LeLieuPage

export const CafePageQuery = graphql`
  query LeCaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        main {
          image0 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
          image2 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
        }
      }
    }
  }
`